body {
  margin: 0;
  background-color: black;
}

.contact {
  display: grid;
  color: white;
  position: fixed;
  top: 20px;
  right: 20px;
  font-weight: bold;
  font-size: 25px;
  background-color: black;
  border-radius: 30px;
  border: solid;
  border-color: black;
  border-width: 1px;
  padding: 10px;
}

.contact:hover {
  border-color: white;
  border-width: 1px;
}

.contactToggled {
  color: white;
  position: fixed;
  top: 20px;
  right: 20px;
  font-weight: bold;
  font-size: 25px;
  background-color: black;
  border-radius: 30px;
  border: solid;
  border-color: black;
  padding: 10px;
}

.flex-container {
  display: flex;
}
